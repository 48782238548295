export default function useFlattenObject(
  obj: Record<string, unknown>,
  parentKey = '',
  result: Record<string, unknown> = {}
) {
  for (const key in obj) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      useFlattenObject(obj[key] as Record<string, unknown>, newKey, result);
    } else {
      result[newKey] = obj[key];
    }
  }
  return result;
}

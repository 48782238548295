// composables/useFormatMoney.ts
import { SystemSettingKeys } from '@openbox/shared-types/lib';
import { useAuthStore } from '../stores/AuthStore';

export default function useFormatMoney(value: number): string {
  const authStore = useAuthStore();

  if (Number.isNaN(value)) {
    return '';
  }

  const decimalPlaces = authStore.getSetting(SystemSettingKeys.DECIMAL_PLACES);

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(value);
}

import { addHours, format } from 'date-fns';

type UseFormatDateSettings =
  | { separator?: '/' | '-' | ''; formatDate?: never; formatTime?: never }
  | {
      formatDate?: 'dd/MM/yyyy' | 'MM/dd/yyyy' | 'yyyy/MM/dd';
      formatTime?: 'HH:mm' | 'HH:mm:ss';
      separator?: never;
    };

export default function useFormatDate(value?: string | Date, settings?: UseFormatDateSettings) {
  const separator = settings?.separator || '/';
  const formatDateString = settings?.formatDate || `dd${separator}MM${separator}yyyy`;
  const formatTimeString = settings?.formatTime || '';
  const formatString = `${formatDateString} ${formatTimeString}`.trim();

  return value ? format(value instanceof Date ? value : addHours(new Date(value), +6), formatString) : value;
}

function cleanObject<T extends object>(obj: T): T {
  return useTransform(obj, (result, value, key) => {
    if (isObject(value)) {
      value = cleanObject(value);
    }

    if (value !== undefined && value !== null && value !== '') {
      if (isObject(value) && isEmpty(value)) {
        return;
      }
      result[key] = value;
    }
  }) as T;
}

export default function useCleanObject<T extends object>(obj: T): T {
  return cleanObject(obj);
}

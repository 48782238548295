import { defineStore } from 'pinia';

export const useLocalStore = defineStore('LocalStore', {
  state: () => ({
    data: {} as Record<string, unknown>,
    highlights: {
      services: null,
      customers: null
    } as Record<string, null | string>
  }),
  actions: {
    update<T>(key: string, value: T) {
      this.data[key] = value;
    },
    remove(key: string) {
      delete this.data[key];
    },
    listen(key: string, id: string) {
      this.highlights[key] = id;
    }
  },
  getters: {
    get:
      state =>
      <T>(key: string): T | undefined => {
        return state.data[key] as T;
      }
  },
  persist: {
    storage: persistedState.localStorage
  }
});
